import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from 'app/common/components/components.module';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ArchwizardModule } from 'angular-archwizard';
import { PipeModule } from 'app/pipes/pipe.module';
import { CompanyProfilePage } from './company-profile/company-profile.page';
import { UserProfileModule } from '../user-profile/user-profile.module';
import { CompanyService } from 'app/services/company.service';
import { EditBaseProductModalForm } from './product-editor/edit-base-product-modal.page';
import { GroupDefinitionComponent } from './entity-editor/group-definition.component.';
import { EditEntityModalForm } from './entity-editor/edit-entity-modal.page';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CompanyUserMappingComponent } from './company-users-permission/company-user-permission.component';
import { ModalValidatorEditor } from './entity-editor/modal-validator-editor.component';
import { CertificateModalForm } from './certificate-page-editor/certificate-page-editor-modal.page';
import { SectionEditorForm } from './certificate-page-editor/section-editor.form';
import { AddEntityModalForm } from './add-entity-modal/add-entity-modal.page';
import { LandingPageSectionEditorForm } from './landing-page-editor/lp-section-editor.form';
import { LandingPageModalForm } from './landing-page-editor/landing-page-editor-modal.page';
import { FieldSelectorModalForm } from './landing-page-editor/field-selector-modal.page';


export const CompanyRoutes: Routes = [
    { path: 'company', component: CompanyProfilePage, canActivate: []},
    { path: 'company/:companyId', component: CompanyProfilePage, canActivate: []},
    
];


@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        RouterModule.forChild(CompanyRoutes),
		NgJsonEditorModule,
		ArchwizardModule,
		PipeModule,
		UserProfileModule,
		NgbModule
    ],
    exports: [
		EditBaseProductModalForm,
		AddEntityModalForm,
		GroupDefinitionComponent,
		EditEntityModalForm,
		CertificateModalForm,
		SectionEditorForm,
		LandingPageSectionEditorForm,
		LandingPageModalForm,
		FieldSelectorModalForm,
		CompanyUserMappingComponent,
		ModalValidatorEditor
	],
    declarations: [
		CompanyProfilePage,
		EditBaseProductModalForm,
		AddEntityModalForm,
		EditEntityModalForm,
		CertificateModalForm,
		SectionEditorForm,
		LandingPageSectionEditorForm,
		LandingPageModalForm,
		FieldSelectorModalForm,
		GroupDefinitionComponent,
		CompanyUserMappingComponent,
		ModalValidatorEditor
    ],
    providers: [
		CompanyService
	],
	entryComponents: [
		EditBaseProductModalForm,
		AddEntityModalForm,
		EditEntityModalForm,
		CertificateModalForm,
		SectionEditorForm,
		LandingPageSectionEditorForm,
		LandingPageModalForm,
		FieldSelectorModalForm,
		GroupDefinitionComponent,
		CompanyUserMappingComponent,
		ModalValidatorEditor
	]
})
export class CompanyModule { }
