import { Injectable } from "@angular/core";
import { UserProfile } from "app/models/profile.models";
import { environment } from "environments/environment";
import moment from "moment";
import { Observable, of } from "rxjs";
import { BlockChainService } from "./blockchain.service";
import { HttpService } from "./http.service";
import { URLUtils } from "./url-utils";
import { CertificatePageDefinition, Company, EntityDefinition, FieldType, Product } from "app/models/entities.model";
import { catchError, map } from "rxjs/operators";


@Injectable({
	providedIn: 'root'
})
export class SwitchDataLakeService {
	// private baseUrl = "http://vs.ofidia.eu:3002/upload/posti";
	private baseUrl = "https://api-gateway.posti.world/switch-data-lake/upload/posti";
	
	constructor(private _httpService: HttpService) {
	}

	public saveCompany = (company: Company): Observable<any> => {
		let body: any = {
			company_id: company.companyId, 
			name: company.name, 
			address: company.address,
			cap: company.cap,
			city: company.city
		};
		if (company.province) body.province = company.province;
 		if (company.logo) body.logo= environment.services.mediaContentsBasePath + "/" + company.logo;
		if (company.email) body.email = company.email;
		if (company.phoneNumber) body.phone_number = company.phoneNumber;
		if (company.additionalInfo) body.additional_info = company.additionalInfo;
		if (company.lastUpdateDate) body.last_update_date = company.lastUpdateDate;

		let url = this.baseUrl + "/company";


		return this._httpService.post(url, body, "json");
	}

	public saveProduct = (data: Product, company: Company): Observable<any> => {
		let body: any = {
			company_id: company.companyId,
			product_name: data.name
		}
		if (data.image) {
			body.product_image= environment.services.mediaContentsBasePath + "/" + data.image;

		}
		data.productFields.forEach((field) => {
			if (field.value != null && field.value != undefined) {
				let f = (""+field.name).split(" ").join("_");
				body[f] = field.value;
			}
			
		})

		let url = this.baseUrl + "/product";
		return this._httpService.post(url, body, "json");
	}

	public saveEntity = (data: any, entityDef: EntityDefinition, product: Product, company: Company): Observable<any> => {
		let body: any = {
			company_id: company.companyId,
			product_name: product.name
		}
		console.log("Data to certify ", data);
		
		for (const group of entityDef.groups)  {
			
				for (const field of group.fields) {
					if (field.fieldToCertify && data[group.groupLabel][field.fieldLabel]) {
						if (data[group.groupLabel][field.fieldLabel] != null && data[group.groupLabel][field.fieldLabel] != undefined) {
							let f = (""+field.fieldLabel).split(" ").join("_");

							if (field.fieldType == FieldType.IMAGE || field.fieldType == FieldType.FILE) {
								body[f] =  environment.services.mediaContentsBasePath + "/"+ data[group.groupLabel][field.fieldLabel];
							}
							else if (field.fieldType == FieldType.DATE) {
								body[f] =  moment(data[group.groupLabel][field.fieldLabel]).format("DD/MM/YYYY");
							}
							else if (field.fieldType == FieldType.MULTIPLE_SELECT) {
								body[f] =  data[group.groupLabel][field.fieldLabel].join(", ");
							}
							else {
								body[f] =  data[group.groupLabel][field.fieldLabel];
							}
						}
						
					}
				}
			
		}
		
		let url = this.baseUrl + "/lot";
		return this._httpService.post(url, body, "json");
	}


	// public delete = (domain: string, id: any): Observable<CertificatePageDefinition> => {
	// 	let url = this.baseUrl + "/" + domain + "/lp_def/" + id;
	// 	return this._httpService.delete(url);
	// }
	// public saveOrUpdate = (domain: string, product: CertificatePageDefinition, toNotify: boolean = false): Observable<CertificatePageDefinition> => {
	// 	let url = this.baseUrl + "/" + domain + "/lp_def";
	// 	if (product.id)
	// 		return this._httpService.put(url + "/"+product.id + "?notify="+toNotify, product,"json");
	// 	return this._httpService.post(url + "?notify="+toNotify,product,"json");
	// }
	
}